import iconSet from 'quasar/icon-set/material-icons-round.js'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@/assets/icons-font/Textmagic-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Loading,
  },
  iconSet: iconSet,
} as any
